<template>
  <v-list dense>
    <v-list-item
      v-for="menuItem in menuItems"
      :key="menuItem.name"
      :to="menuItem.route"
      link
    >
      <v-list-item-action>
        <v-icon>{{ menuItem.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ menuItem.alias }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>

export default {
  name: 'sidebarMenu',
  data: () => ({
    menuItems: [
      {
        alias: window.app.$t('message.admin.users'),
        name: 'users',
        icon: 'mdi-account',
        route: '/admin/users',
      },
      {
        alias: window.app.$t('message.admin.group_management'),
        name: 'group-management',
        icon: 'mdi-account-multiple',
        route: '/admin/group-management',
      },
      {
        alias: window.app.$t('message.admin.subjects_management'),
        name: 'subjects-management',
        icon: 'mdi-human-child',
        route: '/admin/subjects-management',
      },
      {
        alias: window.app.$t('message.admin.room_groups'),
        name: 'room-groups',
        icon: 'mdi-checkbox-blank',
        route: '/admin/room-groups',
      },
    ],
  }),
};
</script>
