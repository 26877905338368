<template>
  <div>
    <v-navigation-drawer v-model="isSidebarShow" app clipped dark>
      <sidebar-menu />
    </v-navigation-drawer>
    <v-app-bar app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="isSidebarShow = !isSidebarShow" />
      <v-toolbar-title>Admin panel</v-toolbar-title>
    </v-app-bar>
    <v-content>
      <router-view />
    </v-content>
  </div>
</template>

<script>
import SidebarMenu from '@components/Admin/SidebarMenu/SidebarMenu.vue';

export default {
  name: 'admin',
  components: {
    SidebarMenu,
  },
  props: {
    source: String,
  },
  data: () => ({
    isSidebarShow: null,
  }),
};
</script>

<style lang="scss">
  html, body {
    overflow: auto !important;
  }
</style>
